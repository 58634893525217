import { Radio } from 'antd';
import React from 'react';
import { CARD_COLORS, CARD_TYPES } from '../../../../common/constant';
const bucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const path = `https://storage.googleapis.com/${bucket}/assets/images`;


const CardColors = ({
  cardMaterial,
  setSelectedColor,
  selectedColor,
}) => {
  const cardColors = {
    metalCard: [
      { icon: `${path}/silver.webp`, name: CARD_COLORS.SILVER },
      { icon: `${path}/gold.webp`, name: CARD_COLORS.GOLD },
      { icon: `${path}/black.webp`, name: CARD_COLORS.BLACK },
    ],
    hybridCard: [
      { icon: `${path}/silver.webp`, name: CARD_COLORS.SILVER },
      { icon: `${path}/gold.webp`, name: CARD_COLORS.GOLD },
      { icon: `${path}/black.webp`, name: CARD_COLORS.BLACK },
    ],
    woodCard: [
      { icon: `${path}/wood-light.webp`, name: CARD_COLORS.BIRCH_LIGHT },
      { icon: `${path}/wood-dark.webp`, name: CARD_COLORS.SAPELE_DARK },
    ],
  };

  const handleCardColorSelect = (e) => {
    setSelectedColor(e.target.value);
  };

  let colorsList;
  if (cardMaterial === CARD_TYPES.HYBRID.name) {
    colorsList = cardColors.hybridCard;
  } else if(cardMaterial === CARD_TYPES.MOBILO_METAL.name) {
    colorsList = cardColors.metalCard;
  } else {
    colorsList = cardColors.woodCard;
  }
  return (
    <div className="card-color">
      <Radio.Group name="card-color" value={selectedColor} onChange={handleCardColorSelect}>
        {colorsList.map((item, index) => (
          <Radio key={item.name || index} value={item.name}>
            <img
              alt="icon"
              src={item.icon}
              className={selectedColor === item.name ? 'selected-card-border' : ''}
            />
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default CardColors;
