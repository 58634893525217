import React from 'react';
import { RESOLUTION_FACTOR } from '../../../../common/constant';

const AntText = ({
  id,
  fontSize,
  color,
  text,
  url,
  fontFamily,
  coordinates,
  transform,
  paddingT,
  paddingR,
  paddingB,
  paddingL,
  alignment
}) => {
  return (
    <span
      id={id}
      style={{
        fontFamily: `${fontFamily}`,
        fontSize: `${fontSize ? fontSize / RESOLUTION_FACTOR : 14}px`,
        color: `${color ? color : '#000'}`,
        whiteSpace: 'pre',
        display: 'inline-block',
        transform: transform,
        position: alignment === 'right' || alignment === 'left' ? 'relative' : 'unset',
        top: `calc(var(--resolutionFactorAdjuster)* ${paddingT / RESOLUTION_FACTOR}px)`,
        right: `calc(var(--resolutionFactorAdjuster)* ${paddingR / RESOLUTION_FACTOR}px)`,
        bottom: `calc(var(--resolutionFactorAdjuster)* ${paddingB / RESOLUTION_FACTOR}px)`,
        paddingLeft: `calc(var(--resolutionFactorAdjuster)* ${paddingL / RESOLUTION_FACTOR}px)`,
      }}
    >
      {text}
    </span>
  );
};

export default AntText;
