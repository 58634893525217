import React from 'react';
import { Result, Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

const ExternalLinkPrompt = () => {
  return (
    <Result
      status="info"
      title="New System Admin Portal (SAM)"
      subTitle="To access the administrative features, please visit our new SAM portal."
      extra={[
        <Button
          type="primary"
          href="https://sam.mobilocard.com"
          target="_blank"
          key="external-link"
        >
          Go to sam.mobilocard.com <LinkOutlined />
        </Button>
      ]}
    />
  );
};

export default ExternalLinkPrompt;
