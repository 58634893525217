import {
  Button,
  Card,
  Divider,
  Form,
  InputNumber,
  message,
  Radio,
  Space,
  Switch,
  Typography
} from 'antd';
import React from 'react';
import { Rnd } from 'react-rnd';
import { LEGACY_DESIGN_EXPERIENCE } from '../../../../common/constant';

function QrPosition({
  type,
  showQr,
  setShowQr,
  qrSide,
  setQrSide,
  backgroundColorFront,
  backgroundColorBack,
  frontCardImage,
  backCardImage,
  qrWidth,
  qrHeight,
  qrX,
  qrY,
  setQrX,
  setQrY,
  setQrWidth,
  setQrHeight,
  base64Str,
  qrRotation,
  setQrRotation,
  layout,
  layout2,
  Slider,
  rotationMarks,
  cardDesign,
  frontNode,
  setFrontNode,
  backNode,
  setBackNode,
  fontFace,
  setIsSaveQrClicked,
  id
}) {
  let backgroundColor;
  let backgroundImage;
  let cardNode;
  let isQrOnFront;
  let isQrOnBack;
  if (type === 'designCentre') {
    if (qrSide === 'FRONT') {
      backgroundColor = backgroundColorFront;
      backgroundImage = `url${frontCardImage}`;
      cardNode = frontNode;
    } else {
      backgroundColor = backgroundColorBack;
      backgroundImage = `url(${backCardImage})`;
      cardNode = backNode;
    }
  } else {
    if (qrSide === 'FRONT') {
      backgroundColor = '#c4c4c4';
      backgroundImage = Object.values(cardDesign)?.length
        ? Object.values(cardDesign)[0]?.frontImageUrl
          ? `url(${Object.values(cardDesign)[0]?.frontImageUrl})`
          : 'none'
        : 'none';
    } else {
      backgroundColor = '#c4c4c4';
      backgroundImage = Object.values(cardDesign)?.length
        ? Object.values(cardDesign)[0]?.backImageUrl
          ? `url(${Object.values(cardDesign)[0]?.backImageUrl})`
          : 'none'
        : 'none';
    }
  }

  const handleQrOnCard = () => {
    if (qrSide === 'FRONT') {
      var frontHtml = document.getElementById(id);
      setFrontNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${frontHtml?.innerHTML}</body></html>`
      );
      message.success('QR code is saved on front side of the card');
    } else {
      var backHtml = document.getElementById(id);
      setBackNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${backHtml?.innerHTML}</body></html>`
      );
      message.success('QR code is saved on back side of the card');
    }
  };
  if (frontNode) {
    let frontParser = new DOMParser();
    const frontDoc = frontParser?.parseFromString(frontNode, 'text/html');
    isQrOnFront = frontDoc?.getElementById('qr');
  }
  if (backNode) {
    let backParser = new DOMParser();
    const backDoc = backParser?.parseFromString(backNode, 'text/html');
    isQrOnBack = backDoc?.getElementById('qr');
  }
  return (
    <>
      <Divider />
      <Card
        size="small"
        title="Position QR code"
        extra={
          <Space size="large">
            {isQrOnFront && isQrOnBack && (
              <Typography.Text type="danger">
                QR code is saved on both the sides
              </Typography.Text>
            )}
            {showQr && (
              <Radio.Group
                defaultValue={qrSide}
                buttonStyle="solid"
                size="small"
                onChange={(e) => setQrSide(e?.target?.value)}
              >
                <Radio.Button value="FRONT">Front</Radio.Button>
                <Radio.Button value="BACK">Back</Radio.Button>
              </Radio.Group>
            )}
            <Space size="small">
              Show QR code
              <Switch
                checked={showQr}
                onChange={(e) => {
                  if (
                    !e &&
                    document
                      ?.querySelector('.background')
                      ?.childNodes[0]?.querySelector('.content')?.childNodes[0]
                  ) {
                    document
                      .querySelector('.background')
                      .childNodes[0].querySelector('.content')
                      .childNodes[0].remove();
                  }
                  setShowQr(e);
                }}
              />
            </Space>
            {type === 'designCentre' && (
              <Button
                type="primary"
                onClick={() => {
                  handleQrOnCard();
                  setIsSaveQrClicked(true);
                }}
                disabled={
                  (qrSide === 'BACK' && isQrOnBack && showQr) ||
                  (qrSide === 'FRONT' && isQrOnFront && showQr)
                }
              >
                Save
              </Button>
            )}
          </Space>
        }
      >
        <Space>
          {type === LEGACY_DESIGN_EXPERIENCE ? (
            <div
              style={{
                overflow: 'hidden',
                width: 405,
                height: 255,
                background: backgroundColor,
                backgroundImage: backgroundImage,
                backgroundSize: 'cover'
              }}
            >
              <Rnd
                style={{ position: 'relative' }}
                lockAspectRatio={true}
                size={{
                  width: qrWidth,
                  height: qrHeight
                }}
                position={{ x: qrX, y: qrY }}
                onDrag={(e, d) => {
                  setQrX(d?.x);
                  setQrY(d?.y);
                }}
                bounds="parent"
                onResizeStop={(e, direction, ref, delta, position) => {
                  setQrWidth(qrWidth + delta?.width);
                  setQrHeight(qrHeight + delta?.height);
                  setQrX(position?.x);
                  setQrY(position?.y);
                }}
              >
                {showQr && (
                  <img
                    src={base64Str ? ` ${base64Str}` : '/qr.png'}
                    style={{
                      width: '100%',
                      height: '100%',
                      transform: `rotate(${qrRotation}deg)`
                    }}
                    alt="QR"
                  />
                )}
              </Rnd>
            </div>
          ) : (
            <div id={id}>
              <Rnd
                className="content"
                style={{ color: '#ffffff', zIndex: 100 }}
                lockAspectRatio={true}
                size={{
                  width: qrWidth,
                  height: qrHeight
                }}
                position={{ x: qrX, y: qrY }}
                onDrag={(e, d) => {
                  setQrX(d?.x);
                  setQrY(d?.y);
                }}
                bounds="parent"
                onResizeStop={(e, direction, ref, delta, position) => {
                  setQrWidth(qrWidth + delta?.width);
                  setQrHeight(qrHeight + delta?.height);
                  setQrX(position?.x);
                  setQrY(position?.y);
                }}
              >
                {showQr && (
                  <img
                    src={base64Str ? `${base64Str}` : '/qr.png'}
                    style={{
                      width: '100%',
                      height: '100%',
                      transform: `rotate(${qrRotation}deg)`
                    }}
                    alt="QR"
                    id="qr"
                  />
                )}
              </Rnd>
              <div
                className="background"
                style={{ top: 0, left: 0, zIndex: -100 }}
              >
                {cardNode && (
                  <div dangerouslySetInnerHTML={{ __html: cardNode }} />
                )}
              </div>
            </div>
          )}
          <Space direction="vertical" style={{ minWidth: 500 }}>
            <Space>
              <Form.Item {...layout} label="QR- W">
                <InputNumber
                  value={qrWidth * 5}
                  min={0}
                  max={2022}
                  onChange={(v) => {
                    setQrWidth(v / 5);
                    setQrHeight(v / 5);
                  }}
                />
              </Form.Item>
              <Form.Item {...layout} label="QR- H">
                <InputNumber
                  value={qrHeight * 5}
                  min={0}
                  max={1275}
                  onChange={(v) => {
                    setQrHeight(v / 5);
                    setQrWidth(v / 5);
                  }}
                />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item {...layout} label="QR- X">
                <InputNumber
                  value={qrX * 5}
                  min={0}
                  max={2022}
                  onChange={(v) => setQrX(v / 5)}
                />
              </Form.Item>
              <Form.Item {...layout} label="QR- Y">
                <InputNumber
                  value={qrY * 5}
                  min={0}
                  max={1275}
                  onChange={(v) => setQrY(v / 5)}
                />
              </Form.Item>
            </Space>

            <Form.Item {...layout2} label="QR- Rotation">
              <Slider
                value={qrRotation}
                onChange={(v) => setQrRotation(v)}
                max={270}
                marks={rotationMarks}
                step={null}
                defaultValue={37}
              />
            </Form.Item>
          </Space>
        </Space>
      </Card>
    </>
  );
}

export default QrPosition;
