import React, { useEffect } from 'react';

//! Antd imports
import { Card, Input } from 'antd';
import { Rnd } from 'react-rnd';
import AntText from './AntText';
import {
  checkFieldInObject,
  getAllCoordinates
} from '../../../../common/utils';
import { RESOLUTION_FACTOR } from '../../../../common/constant';

const AntCard = ({
  ref,
  cardId,
  backgroundColor,
  cardData,
  currentTab,
  newField,
  front,
  setFront,
  back,
  setBack,
  backgroundImage,
  side,
  activeUserTab
}) => {
  return (
    <div
      ref={ref}
      id={cardId}
      style={{
        position: 'relative',
        backgroundColor: `${backgroundColor}`,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        width: 405,
        height: 255
      }}
    >
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.customField?.x / RESOLUTION_FACTOR || 0,
              y: front?.customField?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.customField?.x / RESOLUTION_FACTOR || 0,
              y: back?.customField?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.customField?.alignment &&
              delete front?.customField?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'customField-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.customField?.alignment && delete back?.customField?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'customField-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.customField?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.customField?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {newField?.length > 0 &&
          checkFieldInObject(front, back, 'customField', currentTab) && (
            <AntText
              text={newField}
              id={`customField-${side}`}
              fontSize={
                currentTab === 'front'
                  ? front?.customField?.fontSize
                  : back?.customField?.fontSize
              }
              color={
                currentTab === 'front'
                  ? front?.customField?.color
                  : back?.customField?.color
              }
              url={
                currentTab === 'front'
                  ? front?.customField?.url
                  : back?.customField?.url
              }
              fontFamily={
                currentTab === 'front'
                  ? front?.customField?.family
                  : back?.customField?.family
              }
              coordinates={
                currentTab === 'front' ? front?.customField : back?.customField
              }
              paddingT={currentTab === 'front' ? front?.customField.paddingT : back?.customField.paddingT}
              paddingR={currentTab === 'front' ? front?.customField.paddingR : back?.customField.paddingR}
              paddingB={currentTab === 'front' ? front?.customField.paddingB : back?.customField.paddingB}
              paddingL={currentTab === 'front' ? front?.customField.paddingL : back?.customField.paddingL}
              alignment={(currentTab === 'front' ? front : back)?.customField?.alignment?.toLowerCase()}
            /* Rotation is the part of next sprint so that commenting UI for now */
            // transform={`rotate(${front?.customField?.rotation}deg)`}
            />
          )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.fullName?.x / RESOLUTION_FACTOR || 0,
              y: front?.fullName?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.fullName?.x / RESOLUTION_FACTOR || 0,
              y: back?.fullName?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          front?.fullName?.alignment && delete front?.fullName?.alignment;
          if (currentTab === 'front') {
            getAllCoordinates(
              front,
              setFront,
              'fullName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.fullName?.alignment && delete back?.fullName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'fullName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.fullName?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.fullName?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'fullName', currentTab) && (
          <AntText
            id={`fullName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.fullName?.fontSize
                : back?.fullName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.fullName?.color
                : back?.fullName?.color
            }
            text={`${cardData[activeUserTab]?.fullName}`}
            url={
              currentTab === 'front'
                ? front?.fullName?.url
                : back?.fullName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.fullName?.family
                : back?.fullName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.fullName : back?.fullName
            }
            paddingT={currentTab === 'front' ? front?.fullName.paddingT : back?.fullName.paddingT}
            paddingR={currentTab === 'front' ? front?.fullName.paddingR : back?.fullName.paddingR}
            paddingB={currentTab === 'front' ? front?.fullName.paddingB : back?.fullName.paddingB}
            paddingL={currentTab === 'front' ? front?.fullName.paddingL : back?.fullName.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.fullName?.alignment?.toLowerCase()}
          /* Rotation is the part of next sprint so that commenting UI for now */
          // transform={`rotate(${front?.fullName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.firstName?.x / RESOLUTION_FACTOR || 0,
              y: front?.firstName?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.firstName?.x / RESOLUTION_FACTOR || 0,
              y: back?.firstName?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.firstName?.alignment && delete front?.firstName?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'firstName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.firstName?.alignment && delete back?.firstName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'firstName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.firstName?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.firstName?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'firstName', currentTab) && (
          <AntText
            id={`firstName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.firstName?.fontSize
                : back?.firstName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.firstName?.color
                : back?.firstName?.color
            }
            text={cardData[activeUserTab]?.firstName}
            url={
              currentTab === 'front'
                ? front?.firstName?.url
                : back?.firstName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.firstName?.family
                : back?.firstName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.firstName : back?.firstName
            }
            paddingT={currentTab === 'front' ? front?.firstName.paddingT : back?.firstName.paddingT}
            paddingR={currentTab === 'front' ? front?.firstName.paddingR : back?.firstName.paddingR}
            paddingB={currentTab === 'front' ? front?.firstName.paddingB : back?.firstName.paddingB}
            paddingL={currentTab === 'front' ? front?.firstName.paddingL : back?.firstName.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.firstName?.alignment?.toLowerCase()}
          /* Rotation is the part of next sprint so that commenting UI for now */
          // transform={`rotate(${front?.firstName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.lastName?.x / RESOLUTION_FACTOR || 0,
              y: front?.lastName?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.lastName?.x / RESOLUTION_FACTOR || 0,
              y: back?.lastName?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.lastName?.alignment && delete front?.lastName?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'lastName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.lastName?.alignment && delete back?.lastName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'lastName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.lastName?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.lastName?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'lastName', currentTab) && (
          <AntText
            id={`lastName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.lastName?.fontSize
                : back?.lastName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.lastName?.color
                : back?.lastName?.color
            }
            text={cardData[activeUserTab]?.lastName}
            url={
              currentTab === 'front'
                ? front?.lastName?.url
                : back?.lastName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.lastName?.family
                : back?.lastName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.lastName : back?.lastName
            }
            paddingT={currentTab === 'front' ? front?.lastName.paddingT : back?.lastName.paddingT}
            paddingR={currentTab === 'front' ? front?.lastName.paddingR : back?.lastName.paddingR}
            paddingB={currentTab === 'front' ? front?.lastName.paddingB : back?.lastName.paddingB}
            paddingL={currentTab === 'front' ? front?.lastName.paddingL : back?.lastName.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.lastName?.alignment?.toLowerCase()}
          /* Rotation is the part of next sprint so that commenting UI for now */
          // transform={`rotate(${front?.lastName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.email?.x / RESOLUTION_FACTOR || 0,
              y: front?.email?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.email?.x / RESOLUTION_FACTOR || 0,
              y: back?.email?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.email?.alignment && delete front?.email?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'email-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.email?.alignment && delete back?.email?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'email-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.email?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.email?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'email', currentTab) && (
          <AntText
            id={`email-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.email?.fontSize
                : back?.email?.fontSize
            }
            color={
              currentTab === 'front' ? front?.email?.color : back?.email?.color
            }
            text={cardData[activeUserTab]?.email}
            url={currentTab === 'front' ? front?.email?.url : back?.email?.url}
            fontFamily={
              currentTab === 'front'
                ? front?.email?.family
                : back?.email?.family
            }
            coordinates={currentTab === 'front' ? front?.email : back?.email}
            paddingT={currentTab === 'front' ? front?.email.paddingT : back?.email.paddingT}
            paddingR={currentTab === 'front' ? front?.email.paddingR : back?.email.paddingR}
            paddingB={currentTab === 'front' ? front?.email.paddingB : back?.email.paddingB}
            paddingL={currentTab === 'front' ? front?.email.paddingL : back?.email.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.email?.alignment?.toLowerCase()}
          /* Rotation is the part of next sprint so that commenting UI for now */
          // transform={`rotate(${front?.email?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.linkedIn?.x / RESOLUTION_FACTOR || 0,
              y: front?.linkedIn?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.linkedIn?.x / RESOLUTION_FACTOR || 0,
              y: back?.linkedIn?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.linkedIn?.alignment && delete front?.linkedIn?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'linkedIn-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.linkedIn?.alignment && delete back?.linkedIn?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'linkedIn-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.linkedIn?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.linkedIn?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'linkedIn', currentTab) && (
          <AntText
            id={`linkedIn-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.linkedIn?.fontSize
                : back?.linkedIn?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.linkedIn?.color
                : back?.linkedIn?.color
            }
            text={cardData[activeUserTab]?.linkedIn}
            url={
              currentTab === 'front'
                ? front?.linkedIn?.url
                : back?.linkedIn?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.linkedIn?.family
                : back?.linkedIn?.family
            }
            coordinates={
              currentTab === 'front' ? front?.linkedIn : back?.linkedIn
            }
            paddingT={currentTab === 'front' ? front?.linkedIn.paddingT : back?.linkedIn.paddingT}
            paddingR={currentTab === 'front' ? front?.linkedIn.paddingR : back?.linkedIn.paddingR}
            paddingB={currentTab === 'front' ? front?.linkedIn.paddingB : back?.linkedIn.paddingB}
            paddingL={currentTab === 'front' ? front?.linkedIn.paddingL : back?.linkedIn.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.linkedIn?.alignment?.toLowerCase()}
          /* Rotation is the part of next sprint so that commenting UI for now */
          // transform={`rotate(${front?.email?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.mobilePhone?.x / RESOLUTION_FACTOR || 0,
              y: front?.mobilePhone?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.mobilePhone?.x / RESOLUTION_FACTOR || 0,
              y: back?.mobilePhone?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.mobilePhone?.alignment &&
              delete front?.mobilePhone?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'mobilePhone-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.mobilePhone?.alignment && delete back?.mobilePhone?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'mobilePhone-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.mobilePhone?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.mobilePhone?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'mobilePhone', currentTab) && (
          <AntText
            id={`mobilePhone-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.mobilePhone?.fontSize
                : back?.mobilePhone?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.mobilePhone?.color
                : back?.mobilePhone?.color
            }
            text={cardData[activeUserTab]?.mobilePhone}
            url={
              currentTab === 'front'
                ? front?.mobilePhone?.url
                : back?.mobilePhone?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.mobilePhone?.family
                : back?.mobilePhone?.family
            }
            coordinates={
              currentTab === 'front' ? front?.mobilePhone : back?.mobilePhone
            }
            paddingT={currentTab === 'front' ? front?.mobilePhone.paddingT : back?.mobilePhone.paddingT}
            paddingR={currentTab === 'front' ? front?.mobilePhone.paddingR : back?.mobilePhone.paddingR}
            paddingB={currentTab === 'front' ? front?.mobilePhone.paddingB : back?.mobilePhone.paddingB}
            paddingL={currentTab === 'front' ? front?.mobilePhone.paddingL : back?.mobilePhone.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.mobilePhone?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.mobilePhoneNumber?.x / RESOLUTION_FACTOR || 0,
              y: front?.mobilePhoneNumber?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.mobilePhoneNumber?.x / RESOLUTION_FACTOR || 0,
              y: back?.mobilePhoneNumber?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.mobilePhoneNumber?.alignment &&
              delete front?.mobilePhoneNumber?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'mobilePhoneNumber-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.mobilePhoneNumber?.alignment &&
              delete back?.mobilePhoneNumber?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'mobilePhoneNumber-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.mobilePhoneNumber?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.mobilePhoneNumber?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'mobilePhoneNumber', currentTab) && (
          <AntText
            id={`mobilePhoneNumber-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.mobilePhoneNumber?.fontSize
                : back?.mobilePhoneNumber?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.mobilePhoneNumber?.color
                : back?.mobilePhoneNumber?.color
            }
            text={`${cardData[activeUserTab]?.mobilePhoneNumber}`}
            url={
              currentTab === 'front'
                ? front?.mobilePhoneNumber?.url
                : back?.mobilePhoneNumber?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.mobilePhoneNumber?.family
                : back?.mobilePhoneNumber?.family
            }
            coordinates={
              currentTab === 'front'
                ? front?.mobilePhoneNumber
                : back?.mobilePhoneNumber
            }
            paddingT={currentTab === 'front' ? front?.mobilePhoneNumber.paddingT : back?.mobilePhoneNumber.paddingT}
            paddingR={currentTab === 'front' ? front?.mobilePhoneNumber.paddingR : back?.mobilePhoneNumber.paddingR}
            paddingB={currentTab === 'front' ? front?.mobilePhoneNumber.paddingB : back?.mobilePhoneNumber.paddingB}
            paddingL={currentTab === 'front' ? front?.mobilePhoneNumber.paddingL : back?.mobilePhoneNumber.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.mobilePhoneNumber?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.workPhone?.x / RESOLUTION_FACTOR || 0,
              y: front?.workPhone?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.workPhone?.x / RESOLUTION_FACTOR || 0,
              y: back?.workPhone?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.workPhone?.alignment && delete front?.workPhone?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'workPhone-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.workPhone?.alignment && delete back?.workPhone?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'workPhone-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.workPhone?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.workPhone?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'workPhone', currentTab) && (
          <AntText
            id={`workPhone-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.workPhone?.fontSize
                : back?.workPhone?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.workPhone?.color
                : back?.workPhone?.color
            }
            text={cardData[activeUserTab]?.workPhone}
            url={
              currentTab === 'front'
                ? front?.workPhone?.url
                : back?.workPhone?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.workPhone?.family
                : back?.workPhone?.family
            }
            coordinates={
              currentTab === 'front' ? front?.workPhone : back?.workPhone
            }
            paddingT={currentTab === 'front' ? front?.workPhone.paddingT : back?.workPhone.paddingT}
            paddingR={currentTab === 'front' ? front?.workPhone.paddingR : back?.workPhone.paddingR}
            paddingB={currentTab === 'front' ? front?.workPhone.paddingB : back?.workPhone.paddingB}
            paddingL={currentTab === 'front' ? front?.workPhone.paddingL : back?.workPhone.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.workPhone?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.workPhoneNumber?.x / RESOLUTION_FACTOR || 0,
              y: front?.workPhoneNumber?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.workPhoneNumber?.x / RESOLUTION_FACTOR || 0,
              y: back?.workPhoneNumber?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.workPhoneNumber?.alignment &&
              delete front?.workPhoneNumber?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'workPhoneNumber-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.workPhoneNumber?.alignment &&
              delete back?.workPhoneNumber?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'workPhoneNumber-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.workPhoneNumber?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.workPhoneNumber?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'workPhoneNumber', currentTab) && (
          <AntText
            id={`workPhoneNumber-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.workPhoneNumber?.fontSize
                : back?.workPhoneNumber?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.workPhoneNumber?.color
                : back?.workPhoneNumber?.color
            }
            text={`${cardData[activeUserTab]?.workPhoneNumber}`}
            url={
              currentTab === 'front'
                ? front?.workPhoneNumber?.url
                : back?.workPhoneNumber?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.workPhoneNumber?.family
                : back?.workPhoneNumber?.family
            }
            coordinates={
              currentTab === 'front'
                ? front?.workPhoneNumber
                : back?.workPhoneNumber
            }
            paddingT={currentTab === 'front' ? front?.workPhoneNumber.paddingT : back?.workPhoneNumber.paddingT}
            paddingR={currentTab === 'front' ? front?.workPhoneNumber.paddingR : back?.workPhoneNumber.paddingR}
            paddingB={currentTab === 'front' ? front?.workPhoneNumber.paddingB : back?.workPhoneNumber.paddingB}
            paddingL={currentTab === 'front' ? front?.workPhoneNumber.paddingL : back?.workPhoneNumber.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.workPhoneNumber?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.company?.x / RESOLUTION_FACTOR || 0,
              y: front?.company?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.company?.x / RESOLUTION_FACTOR || 0,
              y: back?.company?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.company?.alignment && delete front?.company?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'company-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.company?.alignment && delete back?.company?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'company-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.company?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.company?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'company', currentTab) && (
          <AntText
            id={`company-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.company?.fontSize
                : back?.company?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.company?.color
                : back?.company?.color
            }
            text={cardData[activeUserTab]?.company}
            url={
              currentTab === 'front' ? front?.company?.url : back?.company?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.company?.family
                : back?.company?.family
            }
            coordinates={
              currentTab === 'front' ? front?.company : back?.company
            }
            paddingT={currentTab === 'front' ? front?.company.paddingT : back?.company.paddingT}
            paddingR={currentTab === 'front' ? front?.company.paddingR : back?.company.paddingR}
            paddingB={currentTab === 'front' ? front?.company.paddingB : back?.company.paddingB}
            paddingL={currentTab === 'front' ? front?.company.paddingL : back?.company.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.company?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.title?.x / RESOLUTION_FACTOR || 0,
              y: front?.title?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.title?.x / RESOLUTION_FACTOR || 0,
              y: back?.title?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.title?.alignment && delete front?.title?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'title-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.title?.alignment && delete back?.title?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'title-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.title?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.title?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'title', currentTab) && (
          <AntText
            id={`title-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.title?.fontSize
                : back?.title?.fontSize
            }
            color={
              currentTab === 'front' ? front?.title?.color : back?.title?.color
            }
            text={cardData[activeUserTab]?.title}
            url={currentTab === 'front' ? front?.title?.url : back?.title?.url}
            fontFamily={
              currentTab === 'front'
                ? front?.title?.family
                : back?.title?.family
            }
            coordinates={currentTab === 'front' ? front?.title : back?.title}
            paddingT={currentTab === 'front' ? front?.title.paddingT : back?.title.paddingT}
            paddingR={currentTab === 'front' ? front?.title.paddingR : back?.title.paddingR}
            paddingB={currentTab === 'front' ? front?.title.paddingB : back?.title.paddingB}
            paddingL={currentTab === 'front' ? front?.title.paddingL : back?.title.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.title?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.addressLine1?.x / RESOLUTION_FACTOR || 0,
              y: front?.addressLine1?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.addressLine1?.x / RESOLUTION_FACTOR || 0,
              y: back?.addressLine1?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.addressLine1?.alignment &&
              delete front?.addressLine1?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'addressLine1-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.addressLine1?.alignment &&
              delete back?.addressLine1?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'addressLine1-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.addressLine1?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.addressLine1?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'addressLine1', currentTab) && (
          <AntText
            id={`addressLine1-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.addressLine1?.fontSize
                : back?.addressLine1?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.addressLine1?.color
                : back?.addressLine1?.color
            }
            text={cardData[activeUserTab]?.addressLine1}
            url={
              currentTab === 'front'
                ? front?.addressLine1?.url
                : back?.addressLine1?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.addressLine1?.family
                : back?.addressLine1?.family
            }
            coordinates={
              currentTab === 'front' ? front?.addressLine1 : back?.addressLine1
            }
            paddingT={currentTab === 'front' ? front?.addressLine1.paddingT : back?.addressLine1.paddingT}
            paddingR={currentTab === 'front' ? front?.addressLine1.paddingR : back?.addressLine1.paddingR}
            paddingB={currentTab === 'front' ? front?.addressLine1.paddingB : back?.addressLine1.paddingB}
            paddingL={currentTab === 'front' ? front?.addressLine1.paddingL : back?.addressLine1.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.addressLine1?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.addressLine2?.x / RESOLUTION_FACTOR || 0,
              y: front?.addressLine2?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.addressLine2?.x / RESOLUTION_FACTOR || 0,
              y: back?.addressLine2?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.addressLine2?.alignment &&
              delete front?.addressLine2?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'addressLine2-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.addressLine2?.alignment &&
              delete back?.addressLine2?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'addressLine2-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.addressLine2?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.addressLine2?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'addressLine2', currentTab) && (
          <AntText
            id={`addressLine2-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.addressLine2?.fontSize
                : back?.addressLine2?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.addressLine2?.color
                : back?.addressLine2?.color
            }
            text={cardData[activeUserTab]?.addressLine2}
            url={
              currentTab === 'front'
                ? front?.addressLine2?.url
                : back?.addressLine2?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.addressLine2?.family
                : back?.addressLine2?.family
            }
            coordinates={
              currentTab === 'front' ? front?.addressLine2 : back?.addressLine2
            }
            paddingT={currentTab === 'front' ? front?.addressLine2.paddingT : back?.addressLine2.paddingT}
            paddingR={currentTab === 'front' ? front?.addressLine2.paddingR : back?.addressLine2.paddingR}
            paddingB={currentTab === 'front' ? front?.addressLine2.paddingB : back?.addressLine2.paddingB}
            paddingL={currentTab === 'front' ? front?.addressLine2.paddingL : back?.addressLine2.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.addressLine2?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.city?.x / RESOLUTION_FACTOR || 0,
              y: front?.city?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.city?.x / RESOLUTION_FACTOR || 0,
              y: back?.city?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.city?.alignment && delete front?.city?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'city-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.city?.alignment && delete back?.city?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'city-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.city?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.city?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'city', currentTab) && (
          <AntText
            id={`city-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.city?.fontSize
                : back?.city?.fontSize
            }
            color={
              currentTab === 'front' ? front?.city?.color : back?.city?.color
            }
            text={cardData[activeUserTab]?.city}
            url={currentTab === 'front' ? front?.city?.url : back?.city?.url}
            fontFamily={
              currentTab === 'front' ? front?.city?.family : back?.city?.family
            }
            coordinates={currentTab === 'front' ? front?.city : back?.city}
            paddingT={currentTab === 'front' ? front?.city.paddingT : back?.city.paddingT}
            paddingR={currentTab === 'front' ? front?.city.paddingR : back?.city.paddingR}
            paddingB={currentTab === 'front' ? front?.city.paddingB : back?.city.paddingB}
            paddingL={currentTab === 'front' ? front?.city.paddingL : back?.city.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.city?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.state?.x / RESOLUTION_FACTOR || 0,
              y: front?.state?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.state?.x / RESOLUTION_FACTOR || 0,
              y: back?.state?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.state?.alignment && delete front?.state?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'state-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.state?.alignment && delete back?.state?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'state-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.state?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.state?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'state', currentTab) && (
          <AntText
            id={`state-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.state?.fontSize
                : back?.state?.fontSize
            }
            color={
              currentTab === 'front' ? front?.state?.color : back?.state?.color
            }
            text={cardData[activeUserTab]?.state}
            url={currentTab === 'front' ? front?.state?.url : back?.state?.url}
            fontFamily={
              currentTab === 'front'
                ? front?.state?.family
                : back?.state?.family
            }
            coordinates={currentTab === 'front' ? front?.state : back?.state}
            paddingT={currentTab === 'front' ? front?.state.paddingT : back?.state.paddingT}
            paddingR={currentTab === 'front' ? front?.state.paddingR : back?.state.paddingR}
            paddingB={currentTab === 'front' ? front?.state.paddingB : back?.state.paddingB}
            paddingL={currentTab === 'front' ? front?.state.paddingL : back?.state.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.state?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.zipcode?.x / RESOLUTION_FACTOR || 0,
              y: front?.zipcode?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.zipcode?.x / RESOLUTION_FACTOR || 0,
              y: back?.zipcode?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.zipcode?.alignment && delete front?.zipcode?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'zipcode-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.zipcode?.alignment && delete back?.zipcode?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'zipcode-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.zipcode?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.zipcode?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'zipcode', currentTab) && (
          <AntText
            id={`zipcode-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.zipcode?.fontSize
                : back?.zipcode?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.zipcode?.color
                : back?.zipcode?.color
            }
            text={cardData[activeUserTab]?.zipcode}
            url={
              currentTab === 'front' ? front?.zipcode?.url : back?.zipcode?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.zipcode?.family
                : back?.zipcode?.family
            }
            coordinates={
              currentTab === 'front' ? front?.zipcode : back?.zipcode
            }
            paddingT={currentTab === 'front' ? front?.zipcode.paddingT : back?.zipcode.paddingT}
            paddingR={currentTab === 'front' ? front?.zipcode.paddingR : back?.zipcode.paddingR}
            paddingB={currentTab === 'front' ? front?.zipcode.paddingB : back?.zipcode.paddingB}
            paddingL={currentTab === 'front' ? front?.zipcode.paddingL : back?.zipcode.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.zipcode?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.country?.x / RESOLUTION_FACTOR || 0,
              y: front?.country?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.country?.x / RESOLUTION_FACTOR || 0,
              y: back?.country?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.country?.alignment && delete front?.country?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'country-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.country?.alignment && delete back?.country?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'country-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        className={`${(currentTab === 'front' ? front : back)?.country?.alignment?.toLowerCase() === 'right' ? 'transformZero rightAligned' : ''} ${(currentTab === 'front' ? front : back)?.country?.alignment?.toLowerCase() === 'left' ? 'transformZero leftAligned' : ''}`}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'country', currentTab) && (
          <AntText
            id={`country-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.country?.fontSize
                : back?.country?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.country?.color
                : back?.country?.color
            }
            text={cardData[activeUserTab]?.country}
            url={
              currentTab === 'front' ? front?.country?.url : back?.country?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.country?.family
                : back?.country?.family
            }
            coordinates={
              currentTab === 'front' ? front?.country : back?.country
            }
            paddingT={currentTab === 'front' ? front?.country.paddingT : back?.country.paddingT}
            paddingR={currentTab === 'front' ? front?.country.paddingR : back?.country.paddingR}
            paddingB={currentTab === 'front' ? front?.country.paddingB : back?.country.paddingB}
            paddingL={currentTab === 'front' ? front?.country.paddingL : back?.country.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.country?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
              x: front?.cityStateZipCode?.x / RESOLUTION_FACTOR || 0,
              y: front?.cityStateZipCode?.y / RESOLUTION_FACTOR || 0
            }
            : {
              x: back?.cityStateZipCode?.x / RESOLUTION_FACTOR || 0,
              y: back?.cityStateZipCode?.y / RESOLUTION_FACTOR || 0
            }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.cityStateZipCode?.alignment &&
              delete front?.cityStateZipCode?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'cityStateZipCode-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.cityStateZipCode?.alignment &&
              delete back?.cityStateZipCode?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'cityStateZipCode-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'cityStateZipCode', currentTab) && (
          <AntText
            id={`cityStateZipCode-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.cityStateZipCode?.fontSize
                : back?.cityStateZipCode?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.cityStateZipCode?.color
                : back?.cityStateZipCode?.color
            }
            text={`${cardData[activeUserTab]?.cityStateZipCode}`}
            url={
              currentTab === 'front'
                ? front?.cityStateZipCode?.url
                : back?.cityStateZipCode?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.cityStateZipCode?.family
                : back?.cityStateZipCode?.family
            }
            coordinates={
              currentTab === 'front'
                ? front?.cityStateZipCode
                : back?.cityStateZipCode
            }
            paddingT={currentTab === 'front' ? front?.cityStateZipCode.paddingT : back?.cityStateZipCode.paddingT}
            paddingR={currentTab === 'front' ? front?.cityStateZipCode.paddingR : back?.cityStateZipCode.paddingR}
            paddingB={currentTab === 'front' ? front?.cityStateZipCode.paddingB : back?.cityStateZipCode.paddingB}
            paddingL={currentTab === 'front' ? front?.cityStateZipCode.paddingL : back?.cityStateZipCode.paddingL}
            alignment={(currentTab === 'front' ? front : back)?.cityStateZipCode?.alignment?.toLowerCase()}
          />
        )}
      </Rnd>
    </div>
  );
};

export default AntCard;
